
import { defineComponent } from 'vue';
import { ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useStore } from 'vuex';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
export default defineComponent({
  name: 'Ekyc view',
  components: {
    DefaultLayout,
    IconCheckCircle,
  },
  setup() {
    const store = useStore();
    const user = store.getters.getUser;
    const nicImageFront = ref(user.nicFrontImageUrl);
    const nicImageBack = ref(user.nicBackImageUrl);
    const selfieImage = ref(user.facePhotoImageUrl);
    return {
      nicImageFront,
      nicImageBack,
      selfieImage,
    };
  },
});
