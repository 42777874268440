<template>
  <default-layout>
    <div class="view-ekyc default-layout-main">
      <div class="nicImageFront">
        <p class="mt-3 font-bold">Mặt trước CMND</p>
        <label for="upload-photo-front">
          <div
            class="image-ID border border-dashed border-tertiary bg-nic-front"
          >
            <img
              v-if="nicImageFront"
              :src="nicImageFront"
              class="preview-image"
              id="image-front"
            />
            <icon-check-circle
              v-if="uploadImaFrontSuccess"
              class="text-primary absolute right-4 top-2 w-10 h-10"
            ></icon-check-circle>
          </div>
        </label>
      </div>

      <div class="nicImageBack">
        <p class="mt-3 font-bold required">Mặt sau CMND</p>
        <label for="upload-photo-back">
          <div
            class="image-ID border border-dashed border-tertiary bg-nic-back"
          >
            <img
              v-if="nicImageBack"
              :src="nicImageBack"
              class="preview-image"
              id="image-back"
            />
          </div>
        </label>
      </div>
      <div class="selfieImage">
        <p class="mt-3 font-bold required">Ảnh khuôn mặt</p>
        <label for="upload-photo-selfieImage">
          <div
            class="image-ID border border-dashed border-tertiary selfie bg-selfie"
          >
            <img
              v-if="selfieImage"
              :src="selfieImage"
              class="preview-image"
              id="image-selfie"
            />
          </div>
        </label>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useStore } from 'vuex';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
export default defineComponent({
  name: 'Ekyc view',
  components: {
    DefaultLayout,
    IconCheckCircle,
  },
  setup() {
    const store = useStore();
    const user = store.getters.getUser;
    const nicImageFront = ref(user.nicFrontImageUrl);
    const nicImageBack = ref(user.nicBackImageUrl);
    const selfieImage = ref(user.facePhotoImageUrl);
    return {
      nicImageFront,
      nicImageBack,
      selfieImage,
    };
  },
});
</script>
<style lang="scss" scoped>
.view-ekyc {
  padding-bottom: 50px !important;
  .nicImageFront,
  .nicImageBack,
  .selfieImage {
    .image-ID {
      border-radius: 15px;
      position: relative;
      text-align: center;
      height: 200px;
      max-width: 300px;
      margin: auto;
      overflow: hidden;
      background-size: 100% 100%;

      &.selfie {
        max-height: 300px;
        height: 300px;

        .upload-selfie {
          position: absolute;
          top: 50%;
          left: 50%;
          height: auto;
        }
      }

      .rolate-img {
        height: 320px !important;
        width: 200px;
        transform: rotate(-90deg) translateX(30%) translateY(15%);
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .view-ekyc {
    .nicImageFront,
    .nicImageBack {
      .image-ID {
        .rolate-img {
          height: 265px !important;
          transform: rotate(-90deg) translateX(20%) translateY(12%);
        }
      }
    }
  }
}
#image-front,
#image-back {
  width: 300px;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
</style>
